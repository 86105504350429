import React, { createRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  addPaymentDetails,
  updatePaymentDetails,
  uploadDocApi,
} from '../../../service/BankingService';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';

const AddNewBank = ({ title, type, onClose, getBankDetails }) => {
  const toast = useToast();
  const { bankData } = useSelector((state) => state?.bank);

  const paymentDetailsId = useSelector(
    (state) => state?.bank?.bankData?.paymentDetailsId
  );
  const BankName = useSelector(
    (state) => state?.bank?.bankData?.bankName || ''
  );

  const [filters, setFilters] = useState([
    {
      name: 'All',
      event: null,
      selected: true,
    },
    {
      name: 'Deposit',
      event: 'DEPOSIT_TO_WALLET',
      selected: false,
    },
    {
      name: 'Withdrawal',
      event: 'WITHDRAW_FROM_WALLET',
      selected: false,
    },
    {
      name: 'Deposit to ID',
      event: 'DEPOSIT_TO_ID',
      selected: false,
    },
    {
      name: 'Withdrawal from ID',
      event: 'WITHDRAW_FROM_ID',
      selected: false,
    },
    {
      name: 'Create ID',
      event: 'CREATE_ID',
      selected: false,
    },
    {
      name: 'Other',
      event: 'OTHER',
      selected: false,
    },
  ]);

  const [formData, setFormData] = useState({
    accountHolderName: '',
    accountNumber: '',
    bankType: null, // [BANK_SAVING,BANK_CURRENT,UPI_PAYTM,UPI_PHONEPE,UPI_GPAY,UPI_BHIM,]
    ifscCode: '',
    mobile: null,
    bankName: '',
    paymentDetailsId: '',
    qrCodeImageUrl: '',
    upiId: '',
    type: 'upi', //[upi,bank]
    isMethodActive: false,
  });

  const uploadFileRef = createRef();
  const [qrImage, setQrImage] = useState(null);
  const navigate = useNavigate();
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    uploadDoc(event.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    }));
  };

  const resetForm = () => {
    setFormData({
      accountHolderName: '',
      accountNumber: '',
      bankType: null, // [BANK_SAVING,BANK_CURRENT,UPI_PAYTM,UPI_PHONEPE,UPI_GPAY,UPI_BHIM,]
      ifscCode: '',
      mobile: null,
      bankName: '',
      paymentDetailsId: '',
      qrCodeImageUrl: '',
      upiId: '',
      type: 'upi', //[upi,bank]
      isMethodActive: false,
    });
  };

  useEffect(() => {
    if (type === 'Add') {
      resetForm();
    }
  }, [type]);

  useEffect(() => {
    setFormData({
      ...formData,
      accountHolderName: bankData?.accountHolderName || '',
      accountNumber: bankData?.accountNumber || '',
      bankType: bankData?.bankType || null,
      ifscCode: bankData?.ifscCode || '',
      mobile: null,
      bankName: bankData?.bankName || '',
      paymentDetailsId: '',
      qrCodeImageUrl: bankData?.qrCodeImageUrl || '',
      upiId: bankData?.upiId || '',
      type: bankData?.type || 'upi',
      isMethodActive: bankData?.isMethodActive || false,
    });
  }, [bankData]);

  const uploadDoc = async (file) => {
    const formDataToSend = new FormData();
    formDataToSend.append('file', file);
    const res = await uploadDocApi(formDataToSend);
    if (res?.status === 200) {
      console.log(res, 'data');
      toast({
        title: 'Document Uploaded',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      const uri = res?.data?.data['File Url'].CREATED;
      setDocumentUrl(uri);
      setFormData({ ...formData, qrCodeImageUrl: uri });
      console.log('doc', res);
    }
  };

  console.log(documentUrl, 'documentUrl');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (type === 'Add') {
      const response = await addPaymentDetails(formData);
      if (response.status === 200) {
        toast({
          title: 'Account added',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        getBankDetails();
        onClose();
      } else {
        toast.error('Error Occured');
      }
      console.log('addpost', response);
    } else {
      var filteredPayload = Object.keys(formData)
        .filter((key) => formData[key] !== null)
        .reduce((obj, key) => {
          obj[key] = formData[key];
          return obj;
        }, {});
      filteredPayload = {
        ...filteredPayload,
        qrCodeImageUrl: documentUrl,
      };

      const response = await updatePaymentDetails(
        paymentDetailsId,
        filteredPayload
      );
      if (response.status === 200) {
        onClose();
        toast.success('Account updated');
        getBankDetails();
      } else {
        toast({
          title: 'Error Occured',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const isButtonDisabled = () => {
    let isValid = false;

    if (formData.type === 'upi') {
      if (
        (!selectedFile && !formData.upiId) ||
        !formData.accountHolderName ||
        !formData.bankType ||
        (!formData.upiId && !selectedFile)
      ) {
        isValid = true;
      }
    }
    if (formData.type === 'bank') {
      if (
        !formData.accountNumber ||
        !formData.accountHolderName ||
        !formData.bankName ||
        !formData.ifscCode ||
        !formData.bankType
      ) {
        isValid = true;
      }
    }

    return isValid;
  };

  return (
    <div className='d-flex justify-content-center  '>
      <div
        className='position-relative  w-sm-50% w-md-40% w-lg-50% w-100  '
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ border: 10 }}>
          <form>
            <Flex gap={'15px'}>
              <Box width={'50%'}>
                <FormControl>
                  <FormLabel>Payment Type</FormLabel>
                  <Select
                    placeholder='Payment Type'
                    value={formData.type}
                    onChange={handleChange}
                    disabled={type === 'Update'}
                  >
                    <option value='upi'>UPI</option>
                    <option value='bank'>Bank</option>
                  </Select>
                </FormControl>
              </Box>
            </Flex>

            {formData.type !== 'upi' && (
              <>
                <Flex gap={'15px'} mt='15px'>
                  <Box>
                    <FormControl>
                      <FormLabel>Account holder Name</FormLabel>
                      <Input
                        type='text'
                        placeholder='Account Holder Name'
                        name='accountHolderName'
                        value={formData.accountHolderName}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl>
                      <FormLabel>Account Number</FormLabel>
                      <Input
                        type='text'
                        placeholder='Account Number'
                        name='accountNumber'
                        value={formData.accountNumber}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                </Flex>

                <Flex
                  gap={'15px'}
                  style={{ marginTop: '7px', paddingBottom: '20px' }}
                >
                  <Box>
                    <FormControl>
                      <FormLabel>Bank Name</FormLabel>
                      <Input
                        type='text'
                        placeholder='Bank Name'
                        name='bankName'
                        value={formData.bankName}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>

                  <Box>
                    <FormControl>
                      <FormLabel>IFSC CODE</FormLabel>
                      <Input
                        type='text'
                        placeholder='IFSC Code'
                        name='ifscCode'
                        value={formData.ifscCode}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                </Flex>
              </>
            )}

            <Flex
              gap={'15px'}
              style={{ paddingBottom: '20px', width: '590px', marginTop: 15 }}
            >
              {formData.type === 'upi' && (
                <>
                  <Box width={'33.333%'}>
                    <FormControl>
                      <FormLabel>UPI ID</FormLabel>
                      <Input
                        type='text'
                        placeholder='UPI ID'
                        name='upiId'
                        value={formData.upiId}
                        onChange={handleChange}
                        disabled={type === 'Update'}
                      />
                    </FormControl>
                  </Box>
                  <Box width={'33.333%'}>
                    <FormControl>
                      <FormLabel>Account holder Name</FormLabel>
                      <Input
                        type='text'
                        placeholder='Account Holder Name'
                        name='accountHolderName'
                        value={formData.accountHolderName}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                </>
              )}

              <Box width={'33.333%'}>
                <FormControl>
                  <FormLabel>Bank Type</FormLabel>
                  <Select
                    placeholder='Bank Type'
                    name='bankType' // Make sure to set the name attribute
                    value={formData.bankType}
                    onChange={handleChange}
                    disabled={type === 'Update'}
                  >
                    <option value='-1'>Select</option>
                    {formData.type === 'upi' && (
                      <>
                        {' '}
                        <option value='UPI_PAYTM'>Paytm</option>
                        <option value='UPI_PHONEPE'>PhonePe Current</option>
                        <option value='UPI_GPAY'>Gpay</option>
                        <option value='UPI_BHIM'>UPI Bhim Current</option>
                      </>
                    )}
                    {formData.type !== 'upi' && (
                      <>
                        {' '}
                        <option value='BANK_SAVING'>Bank Saving</option>
                        <option value='BANK_CURRENT'>Bank Current</option>
                      </>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Flex>

            <Flex gap={'15px'} style={{ marginRight: '280px' }} mb='3'>
              <Box width={'25%'}>
                <FormLabel>Is Active</FormLabel>
              </Box>
              <Box>
                <div class='form-check form-switch'>
                  <input
                    class='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckChecked'
                    name='isMethodActive'
                    value={formData.type}
                    onChange={handleChange}
                    checked={formData.isMethodActive}
                  />
                </div>
              </Box>
            </Flex>
            {formData.type === 'upi' && (
              <div className='mb-4'>
                <p className='mb-2'>Upload QR Code</p>
                <input type='file' onChange={handleFileChange} />
                {formData.qrCodeImageUrl && !selectedFile ? (
                  <img
                    src={formData.qrCodeImageUrl}
                    alt=''
                    width={'100px'}
                    height={'100px'}
                  />
                ) : (
                  'NA'
                )}
              </div>
            )}
            <Flex justify={'center'} mt='20px' mb='25px'>
              <Button
                colorScheme='blue'
                w={'150px'}
                style={{
                  cursor: isButtonDisabled() ? 'not-allowed' : 'pointer',
                }}
                onClick={handleSubmit}
                isDisabled={isButtonDisabled()}
              >
                {' '}
                {type}
              </Button>
            </Flex>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewBank;

import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { AXIOS_INSTANCE } from '../../../service';
import { BASE_URL, BASE_URL_BANKING } from '../../../utils/constants';
import {
  Box,
  Button,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  getBankDetailsAPI,
  getSignedUrlAPI,
} from '../../../service/BankingService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setBankData } from '../../../redux/features/bankSlice';
import AddNewBank from '../AddNewBank';

export default function BankDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState('Add');

  const getBankDetails = useCallback(async () => {
    const res = await getBankDetailsAPI();
    if (res.status === 200) {
      setPaymentDetails(res?.data?.data?.PaymentDetails);
    }
  }, []);

  useEffect(() => {
    getBankDetails();
  }, [getBankDetails]);

  const handleBackClick = () => {
    navigate(-1); // This will navigate back one step
  };

  const handleUpdateClick = (data) => {
    setType('Update');
    dispatch(setBankData(data));
    onOpen();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const itemsPerPage = 5;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className='main'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12 main-container'>
            <div>
              <div className='listing-grid'>
                <div className='detail-row'>
                  <h2>Bank Details</h2>
                </div>
                <Flex mt='25px'>
                  <Button
                    colorScheme='blue'
                    onClick={() => {
                      dispatch(setBankData(null));
                      setType('Add');
                      onOpen();
                    }}
                  >
                    {' '}
                    Add Payment Details
                  </Button>
                </Flex>

                <div
                  style={{ marginTop: '25px' }}
                  className='table-responsive data-table'
                  id='tbldata'
                >
                  <div
                    id='table-active_bets_wrapper'
                    className='dataTables_wrapper no-footer'
                  >
                    <table
                      id='table-active_bets'
                      className='table table-bordered datatable dataTable no-footer'
                      role='grid'
                      aria-describedby='table-active_bets_info'
                    >
                      <thead>
                        <tr role='row' style={{ backgroundColor: '#C9C9C9' }}>
                          <th>Bank Name</th>
                          <th
                            className='sorting_asc'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-sort='ascending'
                            aria-label='Event Type: activate to sort column descending'
                            style={{ width: '141px' }}
                          >
                            QR Image
                          </th>
                          <th
                            className='sorting'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Event Name: activate to sort column ascending'
                            style={{ width: '156px' }}
                          >
                            Account Number
                          </th>
                          <th
                            className='sorting'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Username: activate to sort column ascending'
                            style={{ width: '133px' }}
                          >
                            IFSC Code
                          </th>

                          <th
                            className='sorting'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Bet Type: activate to sort column ascending'
                            style={{ width: '115px' }}
                          >
                            Account Holder Name{' '}
                          </th>
                          <th
                            className='sorting'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='User Rate: activate to sort column ascending'
                            style={{ width: '128px' }}
                          >
                            UPI ID
                          </th>
                          <th
                            className='sorting'
                            tabIndex={0}
                            aria-controls='table-active_bets'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Amount: activate to sort column ascending'
                            style={{ width: '106px' }}
                          >
                            Status
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody id='bet_tbody'>
                        {paymentDetails
                          .sort(
                            (a, b) => a.paymentDetailsId - b.paymentDetailsId
                          )
                          .map((data, index) => (
                            <tr key={data.paymentDetailsId}>
                              <td>{data.bankName || 'UPI'}</td>
                              <td>
                                {data.qrCodeImageUrl ? (
                                  <img
                                    src={data.qrCodeImageUrl}
                                    alt=''
                                    width={'30px'}
                                    height={'30px'}
                                  />
                                ) : (
                                  'NA'
                                )}
                              </td>
                              <td>{data.accountNumber || 'NA'}</td>
                              <td>{data.ifscCode || 'NA'}</td>
                              <td>{data.accountHolderName || 'NA'}</td>
                              <td>{data.upiId || 'NA'}</td>

                              <td>
                                <Text>
                                  {data.isMethodActive ? 'Active' : 'Inactive'}
                                </Text>
                              </td>
                              <td>
                                <Button
                                  colorScheme='blue'
                                  onClick={() => handleUpdateClick(data)}
                                  mt={'5px'}
                                  mb={'5px'}
                                >
                                  Update
                                </Button>
                              </td>
                              {/* Add more columns as needed */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW='700px'>
          <ModalHeader> {type} Bank details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddNewBank
              type={type}
              onClose={onClose}
              getBankDetails={getBankDetails}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
